import React from "react"

const singlePage = () => {
  return (
    <div>

    </div>
  )
}

export default singlePage
